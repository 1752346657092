.bodyBackground() {
  background: url(../images/elsevier-tree-background.jpg);
  background-size: 100% 100%;
}

@loginBoxBg: url(../images/login/acres-wordmark-white.png) top center no-repeat;
@labelColor: #A0A0A0;

html {
  height: 100%;
}

body {
  .bodyBackground();
}

#invalidBrowserMessage {
  margin: 0 auto;
  width: 385px;
}

html, body {
  height: 100%;
  margin: 0;
}

.loginbox {
  background: @loginBoxBg;
  padding-top: 120px;
  border-top: 40px solid transparent;
  min-height: 100%;
  .fields {
    &:extend(#invalidBrowserMessage);
    padding: 20px 30px;
    background: white;

    label {
      color: @labelColor;
    }
  }

  #easyLogin {
    &:extend(.loginbox .fields);
    margin-top: 40px;

    h3 {
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: 550px) {
  .loginbox {
    margin-bottom: -230px;
  }

  .footer-spacer {
    height: 230px;
  }
}

@media only screen and (min-width: 550px) {
  .loginbox {
    margin-bottom: -144px;
  }

  .footer-spacer {
    height: 144px;
  }
}